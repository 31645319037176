/* Projects Page Styling */
.projects-container {
    padding: 20px;
}

.projects-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.project-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.project-card-header {
    text-align: center;
    padding-bottom: 15px;
}

.project-image {
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
}

.project-details {
    padding-top: 15px;
    font-size: 14px;
    color: #555;
}

.github-link {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.github-link:hover {
    text-decoration: underline;
}

.project-card h3 {
    font-size: 18px;
    color: #333;
    margin: 10px 0;
}

.project-card p {
    color: #666;
    font-size: 14px;
}
